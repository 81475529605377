export const tools = [
    "BOOTSTRAP",
    "GIT",
    "GITHUB",
    "COMMANDLINE",
    "AGILE",
    "SCRUM",
    "AWS",
    "TWILIOAPI",
    "AUTH0API",
    "CLOUDINARYAPI",
    "TAILWIND",
]