
export const frontend = [
    "HTML",
    "JAVASCRIPT",
    "CSS",
    "AJAX",
    "JINJA2",
    "JSON",
    "jQUERY",
    "REACT",
]
